import React from 'react';
import CountryGuide from '../../../../components/guides/CountryComponent';
import Footer from '../../../../components/FooterComponent';

import { Scotland } from '../../../../guides/countries/scotland/scotland';

function ScotlandPage(props) {
    return (
        <div>
            <CountryGuide country={ Scotland } pathname={ props.location.pathname } urlBack="/travel-guides/europe" />
            <Footer />
        </div>
    );
}

export default ScotlandPage;
