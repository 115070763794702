import React from 'react';

export const Scotland = {
    "name": "Scotland",
    "link": "scotland",
    "continent": "Europe",
    "caption": "The Land of Iconic Scenarios",

    "description": "Scotland is a land with a rich and multifaceted history, a place where every corner of the landscape is steeped in the past - a deserted croft on the shore of an island, a heathland that was once a battlefield, a cave that housed Bonnie Prince Charlie. Hundreds of castles, from the flat but forbidding tower houses of Hermitage and Smailholm to the elaborate machined forts of Caerlaverock and Craigmillar, testify to the country's often turbulent past. With natural landscapes that will take your breath away, you will be amazed by all the magic that you will have the opportunity to visit through the long mountains of the highlands.",

    "thumb": "https://cdn.pixabay.com/photo/2014/11/21/03/24/mountains-540115_1280.jpg",

    "images": [
        "https://cdn.pixabay.com/photo/2014/11/21/03/24/mountains-540115_1280.jpg",


        "https://cdn.pixabay.com/photo/2013/10/09/02/26/cattle-192976_1280.jpg",
        "https://cdn.pixabay.com/photo/2017/06/24/23/03/railway-2439189_1280.jpg",
        "https://cdn.pixabay.com/photo/2016/08/29/20/16/highlands-and-islands-1629079_1280.jpg",
        "https://cdn.pixabay.com/photo/2017/01/03/22/53/eilean-donan-1950870_1280.jpg",
        "https://cdn.pixabay.com/photo/2019/09/20/09/36/edinburgh-4491305_1280.jpg"
    ],

    "whenVisit": <p>
        The high season occurs during spring (late March to May) and fall (September to November). This is considered the best seasons to travel because it's when the
        average temperatures range from 6ºC to 15ºC, and you can still find snow in the mountains of the Highlands and the Cairngorms.
        The peak in tourists occurs during the summer season due to the high temperatures. The prices usually tend to be higher in this season as well.
        <br/><br/>
        Personally, we recommend the months from <b>April to May</b>, <b>early June</b> the latest, which is slightly before the masses of tourists arrive and with reasonable weather. It’s
        the perfect cost-benefit choice.
    </p>,

    "mobility": <p>
        In Scotland you an array of ways to get around. As it's not a big country, you will be able to move from point A to B of your tour in a couple of hours.
        However, this is not the cheapest country to move around.
        Within the cities, there are plenty of taxis; and out in the countryside, probably a car is your best option.
        <br/><br/>
        For those who don’t want any complications or work on trips, and aren't looking for the cheapest ways or getting a car for a solo trip, the best option is to check all
        the <b>guided tours</b> available on the market. It’s a more expensive option, but all the logistics will be handled by them outside the big cities.
        <br/><br/>
        <b>Public transportation</b> is a good option to travel too. Not so expensive as a guided tour, the country is well connected by a extensive road and rail transport
        network. Train and bus services provide access to the Scottish towns and cities, and to the many villages and ferry ports. To access the most remote areas, sometimes
        you will need to get a car, ferry or even aeroplane.
        <br/><br/>
        In our opinion, the best way to travel around Scotland is without a doubt <b>by car</b>. It gives you more freedom in your schedule and gives you the opportunity
        to spend the time you want in each place. Also, it is a lot easier to get to the remote areas.
        <br/><br/>
        Driving in Scotland is easy most of the time. Don't forget that they <b>drive on the left</b> all through the UK. However, within a few minutes of practice you will get used
        to it. Just two small advices, first, in the winter months the weather can make driving complicated, so be careful with the rains and the snow. And second, always
        remember to look left when crossing people on a single lane road.
        <br/><br/>
         Personally, we suggest you to rent a car in <a href=" https://www.celticlegend.co.uk/car-rental-prices-scotland/" target="_blank" rel="noopener noreferrer">Celtic Legend</a>. They are
         an intermediary, the cars are supplied by <b>Arnold Clark</b> and they rent at a cheaper price. We can give you a good review of their services.
        <br/><br/>
        If you are looking for the cheapest option, if you are a group of 3 to 5 people, or even higher then car is probably the best one.
    </p>,

    "culture": <p>
        Scotland is marked by its own unique family of languages and dialects, helping to cultivate a strong sense of <b>"Scottish-ness"</b>. However, mostly in the South
        you will easily understand the language. In terms of religion, it retains its own national church, separate from that of England. There are also a large
        minority of Roman Catholics in the community.
        <br/><br/>
        Generally, the people here are <b>nice</b> and <b>very tolerant</b>, and you can communicate easily with most of them.
        <br/><br/>
        <b>LGBT rights</b> in Scotland are in line with the rest of United Kingdom, which are one of the most <b>progressive</b> in all of Europe.
    </p>,

    "survival": [
        {
            "title": "Health",
            "description": <p>
                As in all adventurous trips, be sure to acquire a <b>good insurance</b> for your trip. It's important in case you find any issue during your trip.
                You will be outside your home country, some of you in a place far away, and any health related event or even the need to return home earlier could result in a big expense.
                <br/><br/>
                Personally we recommend the <a href="https://www.iatitravelinsurance.co.uk/" target="_blank" rel="noopener noreferrer">IATI insurances</a>. We have already used it in a few trips and never had any kind of problems with the service.
                <br/><br/>
                Also, if you're outside Europe, 1 or 2 months before your trip, you should consult your doctor or local travel clinic for the latest information on <b>health risks</b> in the United Kingdom and to get any <b>vaccinations</b> you may need.
                Usually, for Scotland the vaccination is the same as the rest of Europe and include:
                <br/>
                <ul style={{ marginTop: '16px' }}>
                    <li>Routine Vaccines (Polio, Chickenpox, Tetanus, Rubella);</li>
                    <li>Hepatitis A and B (recommended)</li>
                    <li>Infants 6 to 11 months old traveling internationally should get 1 dose of measles-mumps-rubella (MMR) vaccine before travel. This dose does not count as part of the routine childhood vaccination series.</li>
                </ul>
                You should bring with you a small "medical kit" with products and medications. It's always useful and can help you in the event of a disease.
                Also be cautious with bug bites.
                <br/><br/>
                Take always in consideration the warnings to keep away from animals. Usually they are not violent, but it's not always guaranteed that they will not defend their territory.
                <br/><br/>
                In therms of water, just be more careful in the most remote areas in private wells, in order to ensure the safety of their drinking water.
                <br/><br/>
                Rabies is a small problem in Scotland. But, if for some reason you are bitten by an animal or licked in a wound, it's safer to go immediately search for medical assistance in order to take the vaccine.
            </p>
        },
        {
            "title": "Safety",
            "description": <p>
                Scotland is a very <b>safe country</b> especially for foreigners, however pickpocketing is a common concern, mainly in the big cities. Abstain from using cameras and cell phones on busy streets and buses.
                Generally, the precautions are similar to most of the countries in Europe.
                <br/><br/>
                Be careful driving on remote areas and tiny streets, because the help could take some time to arrive.
                <br/><br/>
                For <b>solo female travelers</b>, Scotland is a perfectly safe country. Still, you should take the typical precautions if you’re a woman traveling along like using taxis from a reputable agency.
            </p>
        },
        {
            "title": "Visas",
            "description": <p>
                When you’re planning a trip, it's important to find out if you need to secure a visa for travel to that country and how to get it.
                You can find <a href="/travel-tips/visas#uk">here</a> all the information that you need to know related to this topic.
            </p>
        },
        {
            "title": "Money",
            "description": <p>
                Scotland is not a cheap country to travel. Its unit of currency is the <b>Pound Sterling</b> and you will find it abbreviated as “£”.
                Tipping is not usually expected by locals, like in USA or Canada, but, of course, it’s always appreciated.
                <br/><br/>
                Daily expenses, admission charges to castles and museums, food and drinks generally are expensive. On the other side, enjoy the nature, it is <b>free</b>.
            </p>
        },
        {
            "title": "Electricity",
            "description": <p>
                The standard electricity supply in Scotland is <b>230 volts</b>. Plugs normally have two round pins (<b>European style</b>). If you are searching for an adapter use
                the type G adapter.
            </p>
        },
        {
            "title": "Internet",
            "description": <p>
                In all the major cities and tourist centers, you will easily find WiFi in all cafes and accommodations.
                <br/><br/>
                If your roaming plan allows it, use your own device operator to access google maps with mobile data. If not, don't forget to download all the maps and the important informations before going
                to the remote areas.
            </p>
        }
    ],

    "itineraries": [
        {
            "name": "Getaway",
            "description": <p>
                If you only have a long weekend or limited days to be able to visit the country, and plan to do only a <b>getaway</b> trip, we recommend that you choose
                only one of the main cities, <a href=" /travel-guides/europe/Scotland/edinburgh"> Edinburgh </a>
                or <a href=" /travel-guides/europe/Scotland/glasgow">Glasgow</a>. Next to one of them you can
                book a <b>day trip</b> to <b>Loch Ness</b> or other part of the <a href=" /travel-guides/europe/Scotland/highlands">Highlands</a>.
            </p>
        },
        {
            "name": "1 week Itinerary",
            "description": <p>
                In a week-long itinerary you will have the possibility to spread your wings a little more in the face of the possibility of
                visiting the fantastic <a href=" /travel-guides/europe/Scotland/highlands">Highlands</a>.
                For that, we recommend that you <b>rent a car</b> (or if you prefer, a guided tour) and head north for a wonderful adventure. As it is only a week,
                we suggest that you save <b>at least 4 or 5 days</b> to visit the north of the country.
                <br/><br/>
                Head to <b>Inverness</b> to see the famous <b>Loch Ness</b>, and you might be lucky and witness the little Nessie. Then continue your way to the <a href=" /travel-guides/europe/Scotland/isle-of-skye">Isle of Skye</a>.
                Dedicate at least <b>one full day</b> to this area (2 days are the ideal). Upon returning to the South, you cannot miss a passage through <b>Glencoe</b>, <b>Glenfinnan</b>, and the
                <b> Loch Lomond</b>. If you are able to, try to start your trip in <a href=" /travel-guides/europe/Scotland/edinburgh">
                Edinburgh</a> and finish it in <a href=" /travel-guides/europe/Scotland/glasgow"> Glasgow</a>, or vice versa.
            </p>
        },
        {
            "name": "2 Weeks Itinerary",
            "description": <p>
                This is surely the best option to embrace the best of Scotland culture and natural landscape.
                <br/><br/>
                With enough time to complete a road trip, we recommend that you spend <b>5 to 7 days</b> to fully discover the wonders
                of <a href=" /travel-guides/europe/Scotland/highlands">Highlands</a> and
                <a href=" /travel-guides/europe/Scotland/isle-of-skye"> Isle of Skye</a>. Again, the ideal will be
                starting your way in <a href=" /travel-guides/europe/Scotland/edinburgh">Edinburgh </a>
                and end on <a href=" /travel-guides/europe/Scotland/glasgow">Glasgow</a>, or vice versa.
                With enough time, we also recommend that you stop by <b>Stirling</b>.
            </p>
        }
    ],

    "guides": [
        {
            "title": "Scotland Travel Guides",
            "places": [
                {
                    "name": "Edinburgh",
                    "image": "https://cdn.pixabay.com/photo/2017/03/16/00/22/edinburgh-2147875_1280.jpg",
                    "link": "edinburgh"
                },
                {
                    "name": "Glasgow",
                    "image": "https://cdn.pixabay.com/photo/2017/03/25/12/38/glasgow-2173449_1280.jpg",
                    "link": "glasgow"
                },
                {
                    "name": "Highlands",
                    "image": "https://cdn.pixabay.com/photo/2014/11/21/03/24/mountains-540116_1280.jpg",
                    "link": "highlands"
                },
                {
                    "name": "Isle of Skye",
                    "image": "https://cdn.pixabay.com/photo/2013/10/09/02/26/lake-192983_1280.jpg",
                    "link": "isle-of-skye"
                }
            ]
        }
    ],

    "destinations": [
        {
            "title": "Off The Beaten Path",
            "description": "Get out of the tourist track and visit some secret places in Scotland. On these spots you will find the true culture of the country and you can immerse yourself on amazing, beautiful locations yet unspoiled by tourists.",
            "places": [
                {
                    "name": "Loch Glencoul & Glencoul Bothy",
                    "description": <p>
                        It's not easy to get to this place. The trek to Glencoul bothy is fitted to the <b>adventurous</b>. To get to the bothy you will need to
                        hike from Inchnadamph or Kylesku through some of Scotland’s roughest unmarked terrain. If you choose the way via Inchnadamph you’ll
                        be able to see Britain’s highest waterfall, <b>Eas a’ Chual Aluinn</b>. You can also reach the bothy by sea kayak or boat. However, the effort is
                        really worth it.
                    </p>,
                    "image": "https://cdn.pixabay.com/photo/2019/10/16/20/04/glencoe-valley-4555360_1280.jpg"
                },
                {
                    "name": "Cairnpapple Hill",
                    "description": <p>
                        Cairnpapple Hill is located a quick drive from Edinburgh. Most tourists manage to whiz past West Lothian on their route to Glasgow
                        or to the highlands, but we surely recommend stopping at Cairnpapple Hill, an ancient burial and ceremonial ground that dates back 5,500 years!
                    </p>,
                    "image": "https://upload.wikimedia.org/wikipedia/commons/a/a0/Kite_Aerial_Photo_of_Cairnpapple_Hill.jpg"
                },
                {
                    "name": "Four Border Abbeys",
                    "description": <p>
                        With the possibility to visit in a day trip from Edinburgh or Glasgow, these magnificent abbeys, established in the 1100s, are located near the Scottish Borders.
                        <br/>
                        To escape a little bit from the super-known (but magnificent) Highlands, here you will have the possibility to discover a little more
                        of the country's history heading south. Some of the most famous figures, like the heart of Robert the Bruce's, or Alexander II of
                        Scotland were buried here, and you will be able to know more about their lives.

                    </p>,
                    "image": "https://i2.pickpik.com/photos/89/930/347/melrose-abbey-historical-scotland-ruin-preview.jpg"
                },
                {
                    "name": "Bealach na Bà",
                    "description": <p>
                        This fantastic road will take you to Applecross, a beautiful peninsula, house of only a few hundred of people. The pass is amazing and you'll be
                        astonished with the views from the track. There is a car park on the top for you to be able to get out and look to the views.
                    </p>,
                    "image": "https://cdn.pixabay.com/photo/2016/01/26/13/46/bealach-na-ba-1162468_1280.jpg"
                }
            ]
        }
    ]
}
